<template>
  <Head :globalData="globalData"></Head>
  <Header :globalData="globalData"/>
  <Loader :globalData="globalData"/>
  <div class="alert-deck">
    <Alerts :alerts="alert" v-for="alert in globalData.alerts"/>
  </div>
  <div class="main-container">
    <router-view :globalData="globalData"/>
  </div>
  <Footer :globalData="globalData"></Footer>
</template>

<script>
import { reactive} from 'vue'
import Loader from "./components/Loader"
import Head from './components/Head'
import Header from './components/Header'
import Partners from './components/Partners'
import Footer from './components/Footer'
import Alerts from './components/Alerts'
import {CookieAgree} from './classes/cookieAgree.js'

export default {
  name: 'App',
  props: ['serverData'],
  components: {
   Loader,Head,Header,Partners,Footer,Alerts
  },
  data(){
    const globalData = reactive(this.serverData)
    console.log(this.serverData)
    globalData.loader.status = false

    new CookieAgree()

    return {
      globalData
    }
  }
}
</script>

<style>
</style>
